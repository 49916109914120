import { useEffect, useState } from "react";
import Footer from "../Layout/Footer";
import AgentHeader from "../Layout/Header";
import "react-datepicker/dist/react-datepicker.css";
export default function PrivacyPolicy() {
    return (
        <div id="main-wrapper">
            <div>
                <AgentHeader></AgentHeader>
                <div className="clearfix"></div>
                <div className="py-2 gray-simple position-relative">
                    <div className="container">
                        {/* Search Form */}
                        <div className="row justify-content-center align-items-center">
                            <div className="col-xl-12 col-lg-12 col-md-12">
                                <nav aria-label="breadcrumb">
                                    <ol className="breadcrumb mb-0">
                                        <li className="breadcrumb-item"><a href="#" className="text-primary">Home</a></li>
                                        <li className="breadcrumb-item active" aria-current="page">Privacy Policy</li>
                                    </ol>
                                </nav>
                            </div>
                        </div>
                    </div>
                </div>
                <section className="pt-3">
                    <div className="container">
                        <div className="bg-white shadow-md rounded p-4">
                            <h4 className="text-6">Privacy Policy -  LAND AIR Tours & Travels</h4>
                            <p>LAND AIR Travels PRIVATE LIMITED, (hereinafter LAND AIR) we are committed to protecting the privacy and security of our users' personal information. This Privacy Policy outlines how we collect, use, disclose, and protect the information you provide while using our website and services. By accessing and using our website, you consent to the practices described in this Privacy Policy.</p>
                            <p>This Privacy Policy provides for the practices for handling and securing user's Personal Information (defined hereunder) by LAND AIR and its subsidiaries and affiliates.</p>
                            <p>This Privacy Policy is applicable to any person ("User") who purchase, intend to purchase, or inquire about any product(s) or service(s) made available by LAND AIR through any of LAND AIR customer interface channels including its website, mobile site, mobile app & offline channels including call centers and offices (collectively referred herein as "Sales Channels").</p>
                            <p>For the purpose of this Privacy Policy, wherever the context so requires "you" or "your" shall mean User and the term "we", "us", "our" shall mean LAND AIR. For the purpose of this Privacy Policy, Website means the website(s), mobile site(s) and mobile app(s).</p>
                            <p>By using or accessing the Website or other Sales Channels, the User hereby agrees with the terms of this Privacy Policy and the contents herein. If you disagree with this Privacy Policy please do not use or access our Website or other Sales Channels.</p>
                            <p>This Privacy Policy does not apply to any website(s), mobile sites and mobile apps of third parties, even if their websites/products are linked to our Website. User should take note that information and privacy practices of LAND AIR business partners, advertisers, sponsors or other sites to which LAND AIR provides hyperlink(s), may be materially different from this Privacy Policy. Accordingly, it is recommended that you review the privacy statements and policies of any such third parties with whom they interact.</p>
                            <div className="row mt-4 mb-2">
                                <div className="col-md-12">
                                    <div className="featured-box style-1">
                                        <h4>Information We Collect:</h4>
                                        <ul className="p-3">
                                            <li className="mb-3"><b>Personal Information:</b> We may collect personal information, including but not limited to your name, contact details, payment information, and travel preferences when you use our services.</li>
                                            <li className="mb-3"><b>Registration on the Website:</b> Information which you provide while subscribing to or registering on the Website, including but not limited to information about your personal identity such as name, gender, marital status, religion, age, profile picture etc., your contact details such as your email address, postal addresses, frequent flyer number, telephone (mobile or otherwise) and/or fax numbers. The information may also include information such as your banking details (including credit/debit card) and any other information relating to your income and/or lifestyle; billing information payment history etc. (as shared by you).</li>
                                            <li className="mb-3"><b>Booking Information:</b> Information related to your bookings, such as flight details, accommodation preferences, and travel itineraries, may be collected to facilitate your travel arrangements.</li>
                                            <li className="mb-3"><b>Usage Data:</b> We collect information about how you interact with our website, including pages visited, time spent on the site, and other analytical data using cookies and similar technologies.</li>
                                            <li className="mb-3"><b>Device Information:</b> Information about the device you use to access our website, such as IP address, device type, and browser information, may be collected for security and optimization purposes.</li>
                                        </ul>
                                        <p>Such information shall be strictly used for the aforesaid specified & lawful purpose only. User further understands that LAND AIR may share this information with the end service provider or any other third party for provision and facilitation of the desired booking. LAND AIR will always redact all/any sensitive & confidential information contained in the vaccination certificate, passbook, bank statement or any other identity card submitted for the purpose of availing a service, promotional offer or booking a product on the Website. In case a User does not wish to provide this information or opts for deletion of the information already provided, LAND AIR may not be able to process the desired booking request. LAND AIR will never share any of the above information collected including PAN card details, Vaccination status & certificate , Passport details , Aadhar Card details without their prior consent unless otherwise such action is required by any law enforcement authority for investigation, by court order or in reference to any legal process.</p>
                                    </div>
                                </div>
                                <div className="col-md-12">
                                    <div className="featured-box style-1">
                                        <h4>How We Use Your Information:</h4>
                                        <ul className="p-3">
                                            <li className="mb-3"><b>To Provide Services:</b> We use the information you provide to process bookings, facilitate travel arrangements, and deliver the services you request.</li>
                                            <li className="mb-3"><b>Communication:</b> We may use your contact information to communicate with you about your bookings, provide updates, and respond to your inquiries.</li>
                                            <li className="mb-3"><b>Personalization:</b> Your preferences and usage data may be used to personalize your experience on our website, offering tailored recommendations and content.</li>
                                            <li className="mb-3"><b>Analytics:</b> We use analytical tools to understand how users interact with our website, helping us improve and optimize our services.</li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="col-md-12">
                                    <div className="featured-box style-1">
                                        <h4>HOW LONG DO WE KEEP YOUR PERSONAL INFORMATION?</h4>
                                        <p>LAND AIR will retain your Personal Information on its servers for as long as is reasonably necessary for the purposes listed in this policy. In some circumstances we may retain your Personal Information for longer periods of time, for instance where we are required to do so in accordance with any legal, regulatory, tax or accounting requirements.</p>
                                        <p>Where your personal data is no longer required we will ensure it is either securely deleted or stored in a way which means it will no longer be used by the business.</p>
                                    </div>
                                </div>
                                <div className="col-md-12">
                                    <div className="featured-box style-1">
                                        <h4>COOKIES AND SESSION DATA</h4>
                                        <p>LAND AIR uses cookies to personalize your experience on the Website and the advertisements that maybe displayed. LAND AIR use of cookies is similar to that of any other reputable online companies.</p>
                                        <p>Cookies are small pieces of information that are stored by your browser on your device's hard drive. Cookies allow us to serve you better and more efficiently. Cookies also allow ease of access, by logging you in without having to type your login name each time (only your password is needed); we may also use such cookies to display any advertisement(s) to you while you are on the Website or to send you offers (or similar emails – provided you have not opted out of receiving such emails) focusing on destinations which may be of your interest.</p>
                                        <p>A cookie may also be placed by our advertising servers, or third party advertising companies. Such cookies are used for purposes of tracking the effectiveness of advertising served by us on any website, and also to use aggregated statistics about your visits to the Website in order to provide advertisements in the Website or any other website about services that may be of potential interest to you. The third party advertising companies or advertisement providers may also employ technology that is used to measure the effectiveness of the advertisements. All such information is anonymous. This anonymous information is collected through the use of a pixel tag, which is an industry standard technology and is used by all major websites. They may use this anonymous information about your visits to the Website in order to provide advertisements about goods and services of potential interest to you. No Personal Information is collected during this process. The information so collected during this process, is anonymous, and does not link online actions to a User</p>
                                        <p>Most web browsers automatically accept cookies. Of course, by changing the options on your web browser or using certain software programs, you can control how and whether cookies will be accepted by your browser. LAND AIR supports your right to block any unwanted Internet activity, especially that of unscrupulous websites. However, blocking LAND AIR cookies may disable certain features on the Website, and may hinder an otherwise seamless experience to purchase or use certain services available on the Website. Please note that it is possible to block cookie activity from certain websites while permitting cookies from websites you trust.</p>
                                        <h6>Automatic Logging of Session Data:</h6>
                                        <p>Each time you access the Website your session data gets logged. Session data may consist of various aspects like the IP address, operating system and type of browser software being used and the activities conducted by the User while on the Website. We collect session data because it helps us analyze User’s choices, browsing pattern including the frequency of visits and duration for which a User is logged on. It also helps us diagnose problems with our servers and lets us better administer our systems. The aforesaid information cannot identify any User personally. However, it may be possible to determine a User's Internet Service Provider (ISP), and the approximate geographic location of User's point of connectivity through the above session data.</p>
                                    </div>
                                </div>
                                <div className="col-md-12">
                                    <div className="featured-box style-1">
                                        <h4>CHANGES TO THE PRIVACY POLICY</h4>
                                        <p>We reserve the rights to revise the Privacy Policy from time to time to suit various legal, business and customer requirement. We will duly notify the users as may be necessary.</p>
                                        <p>You may always submit concerns regarding this Privacy Policy via email to us at support@landairtravels.com. LAND AIR shall endeavor to respond to all reasonable concerns and inquires.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <Footer></Footer>
            </div>
        </div>
    )
}