import { useEffect, useRef, useState } from "react";
import Footer from "../Layout/Footer";
import Header from "../Layout/Header";
import APIConfig, { APIACTIVATEURL } from "../Configuration/APIConfig";
import axios from "axios";
import "react-datepicker/dist/react-datepicker.css";
import { Link, useParams, useNavigate, useLocation } from "react-router-dom";
import moment from "moment";
import PageLoader from "../Layout/PageLoader";
import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import airports from '../Data/AirportList.json';
import Collapse from 'react-bootstrap/Collapse';
export default function RoundTrip() {
    const { state } = useLocation();
    const params = useParams();
    const navigate = useNavigate();
    const [open, setOpen] = useState(false);
    const [searchData, setSearchData] = useState({});
    const [onwardFlights, setOnwardFlights] = useState([]);
    const [returnFlights, setReturnFlights] = useState([]);
    const [selectOnwardFlight, setSelectOnwardFlight] = useState(null);
    const [selectReturnFlight, setSelectReturnFlight] = useState(null);
    const [packages, setPackages] = useState([]);
    const [tempPackages, setTempPackages] = useState([]);
    const [airlines, setAirlines] = useState([]);
    const [stops, setStops] = useState([]);
    const [noFlight, setNoFlight] = useState(false);
    const [loading, setLoading] = useState(true);
    const [departurePrice, setDeparturePrice] = useState(0);
    const [returnPrice, setReturnPrice] = useState(0);
    const [totalPrice, setTotalPrice] = useState(0);
    const [totalFare, setTotalFare] = useState(0);
    const [travelerDisplay, setTravelerDisplay] = useState(false);
    const [isOneWay, setIsOneWay] = useState(false);
    const [isDomestic, setIsDomestic] = useState("DOMESTIC");
    const [journeyType, setJourneyType] = useState("ROUNDTRIP");
    const [locationFrom, setLocationFrom] = useState('HYD');
    const [locationTo, setLocationTo] = useState('BOM');
    const [displayFrom, setDisplayFrom] = useState('Shamshabad Rajiv Gandhi Intl Arpt, Hyderabad(HYD)');
    const [displayTo, setDisplayTo] = useState('Chhatrapati Shivaji, Mumbai(BOM)');
    const [cityFrom, setCityFrom] = useState('Hyderabad');
    const [cityTo, setCityTo] = useState('Mumbai');
    const [departureDate, setDepartureDate] = useState(new Date());
    const [returnDate, setReturnDate] = useState(new Date());
    const [adults, setAdults] = useState(1);
    const [kids, setKids] = useState(0);
    const [infants, setInfants] = useState(0);
    const [cabinClass, setCabinClass] = useState("ECONOMY");
    const [errors, setErrors] = useState({});
    const [from, setFrom] = useState('Hyderabad, India');
    const [to, setTo] = useState('Mumbai, India');
    const [userId, setUserId] = useState("00000000-0000-0000-0000-000000000000");
    const [agentId, setAgentId] = useState("00000000-0000-0000-0000-000000000000");
    const [requestedBy, setRequestedBy] = useState("CUSTOMER");

    const headerconfig = {
        headers: {
            Authorization: "Bearer " + localStorage.getItem("userToken"),
        }
    }
    const handleAirlines = (e, value) => {
        const filteredFlights = tempPackages.filter(flight => {
            return flight.tFSegments.some(segment => segment.airline === value);
        });
        setOnwardFlights(filteredFlights.filter(flight => flight.journeyType === 'ONWARD'));
        setReturnFlights(filteredFlights.filter(flight => flight.journeyType === 'RETURN'));
    }
    const handleStops = (e, value) => {
        let filteredFlights = tempPackages.filter(function (p) {
            return p.stops === value
        });
        setOnwardFlights(filteredFlights.filter(flight => flight.journeyType === 'ONWARD'));
        setReturnFlights(filteredFlights.filter(flight => flight.journeyType === 'RETURN'));
        //setFlights(filteredFlights);
    }
    const handlePriceRange = (e, minPrice, maxPrice) => {
        const filteredFlights = tempPackages.filter(flight => {
            const totalFare = flight.tFPriceDetails?.totalPrice || 0;
            return totalFare >= minPrice && totalFare <= maxPrice;
        });
        setOnwardFlights(filteredFlights.filter(flight => flight.journeyType === 'ONWARD'));
        setReturnFlights(filteredFlights.filter(flight => flight.journeyType === 'RETURN'));
    }
    const handleSort = (e, value) => {
        if (value === 0) {
            const sortedOnwardFlightsData = [...onwardFlights].sort((a, b) => {
                const priceA = a.tFPriceDetails ? a.tFPriceDetails?.totalPrice : 0;
                const priceB = b.tFPriceDetails ? b.tFPriceDetails?.totalPrice : 0;
                return priceA - priceB;
            });
            setOnwardFlights(sortedOnwardFlightsData);
            const sortedreturnFlightsData = [...returnFlights].sort((a, b) => {
                const priceA = a.tFPriceDetails ? a.tFPriceDetails?.totalPrice : 0;
                const priceB = b.tFPriceDetails ? b.tFPriceDetails?.totalPrice : 0;
                return priceA - priceB;
            });
            setReturnFlights(sortedreturnFlightsData);
        }
        else {
            const sortedOnwardFlightsData = [...onwardFlights].sort((a, b) => {
                const priceA = a.tFPriceDetails ? a.tFPriceDetails?.totalPrice : 0;
                const priceB = b.tFPriceDetails ? b.tFPriceDetails?.totalPrice : 0;
                return priceB - priceA;
            });
            setOnwardFlights(sortedOnwardFlightsData);
            const sortedreturnFlightsData = [...returnFlights].sort((a, b) => {
                const priceA = a.tFPriceDetails ? a.tFPriceDetails?.totalPrice : 0;
                const priceB = b.tFPriceDetails ? b.tFPriceDetails?.totalPrice : 0;
                return priceB - priceA;
            });
            setReturnFlights(sortedreturnFlightsData);
        }
    }

    const handleDepartureFlight = (e, dflight) => {
        //e.preventDefault();
        let dprice = parseFloat(returnPrice + Math.round(dflight.tFPriceDetails.totalPrice))
        let dfprice = parseFloat(returnPrice + Math.round(dflight.tFPriceDetails.totalFare))
        setDeparturePrice(Math.round(dflight.tFPriceDetails.totalFare));
        setTotalPrice(dprice);
        setTotalFare(dfprice);
        setSelectOnwardFlight(dflight);
    }
    const handleReturnFlight = (e, dflight) => {
        //e.preventDefault();
        let rprice = parseFloat(departurePrice + Math.round(dflight.tFPriceDetails.totalPrice))
        let rfprice = parseFloat(departurePrice + Math.round(dflight.tFPriceDetails.totalFare))
        setReturnPrice(Math.round(dflight.tFPriceDetails.totalFare));
        setTotalPrice(rprice);
        setTotalFare(rfprice);
        setSelectReturnFlight(dflight);
    }
    const handleSubmit = (e, flightsdata) => {
        e.preventDefault();
        var flightsdata = [];
        flightsdata = [...flightsdata, selectOnwardFlight];
        flightsdata = [...flightsdata, selectReturnFlight];
        navigate('/roundtrip-flight/' + params.adults + "/" + params.kids + "/" + params.infants + "/" + params.isDomestic, { state: { flightsdata, searchData } })
    };
    function getTimeFromMins(mins) {
        var h = mins / 60 | 0, m = mins % 60 | 0;
        var durationformat = h + "h " + m + "m";
        return durationformat;
    }
    const GetFlights = (searchQuery) => {
        axios
            .post(APIConfig.APIACTIVATEURL + APIConfig.SEARCHFLIGHTS, searchQuery, { ...headerconfig })
            .then((response) => {
                var responseData = response.data.response;
                if (responseData.succeeded) {
                    setNoFlight(true);
                    var ddata = responseData.data;
                    setOnwardFlights(ddata.filter(flight => flight.journeyType === 'ONWARD'));
                    setReturnFlights(ddata.filter(flight => flight.journeyType === 'RETURN'));
                    setTempPackages(ddata)
                    setStops([...new Set(ddata.map(flight => flight.stops))].sort((a, b) => a - b));
                    setAirlines([...new Set(ddata.flatMap(flight => flight.tFSegments.map(segment => segment.airline)))]);
                    setLoading(false);
                }
                else {
                    setNoFlight(false);
                }
            }).catch(function (error) {
                setNoFlight(false);
            });
    };
    /* Search Form */
    const validate = () => {
        let temp = {};
        temp.displayFrom = displayFrom === "" ? false : true;
        temp.displayTo = displayTo === "" ? false : true;
        setErrors(temp);
        return Object.values(temp).every((x) => x === true);
    };
    const handleJourneyType = (e) => {
        if (e.target.value === "ROUNDTRIP") {
            setIsOneWay(false)
        }
        else {
            setIsOneWay(true)
        }
        setJourneyType(e.target.value)
    }
    const handleSearch = (e) => {
        e.preventDefault();
        if (validate()) {
            const searchQuery = {
                "journeyType": journeyType,
                "locationFrom": locationFrom,
                "locationTo": locationTo,
                "displayFrom": displayFrom,
                "displayTo": displayTo,
                "adults": adults,
                "kids": kids,
                "infants": infants,
                "cabinClass": cabinClass,
                "departureDate": moment(departureDate).format('YYYY-MM-DD'),
                "returnDate": moment(returnDate).format('YYYY-MM-DD'),
                "cityFrom": cityFrom,
                "cityTo": cityTo,
                "userId": userId,
                "agentId": agentId,
                "from": from,
                "to": to,
                "requestedBy":"CUSTOMER",
                "isOneWay": isOneWay,
                "isDomestic": isDomestic
            }
            if (isOneWay) {
                navigate('/oneway-flights/' + adults + "/" + kids + "/" + infants + "/" + params.isDomestic, { state: { searchQuery } });
            }
            else {
                if (params.isDomestic === "INTERNATIONAL") {
                    navigate('/oneway-flights/' + adults + "/" + kids + "/" + infants + "/" + params.isDomestic, { state: { searchQuery } });
                }
                else {
                    navigate('/roundtrip-flights/' + adults + "/" + kids + "/" + infants + "/" + params.isDomestic, { state: { searchQuery }, replace: true });
                    BindPageData(searchQuery)
                }
            }

        }
    }
    const handleDisplayClick = () => {
        setTravelerDisplay(travelerDisplay => !travelerDisplay);
    }
    const handleClassType = (e) => {
        setCabinClass(e.target.value)
    }
    const handleIncreaseAdults = (e) => {
        setAdults(parseFloat(adults + 1))
    }
    const handleDecreaseAdults = (e) => {
        if (adults !== 1) {
            setAdults(parseFloat(adults - 1))
        }
    }
    const handleIncreaseKids = (e) => {
        setKids(parseFloat(kids + 1))
    }
    const handleDecreaseKids = (e) => {
        if (kids !== 0) {
            setKids(parseFloat(kids - 1))
        }
    }
    const handleIncreaseInfants = (e) => {
        setInfants(parseFloat(infants + 1))
    }
    const handleDecreaseInfants = (e) => {
        if (infants !== 0) {
            setInfants(parseFloat(infants - 1))
        }
    }
    const handleDepartureDate = (date) => {
        if (date !== null) {
            setDepartureDate(date)
            addDays(date)
        }
    }
    const handleReturnDate = (date) => {
        if (date !== null) {
            setReturnDate(date)
            addDays(date)
        }
    }
    const addDays = (departureDate) => {
        if (departureDate === undefined) {
            departureDate = new Date();
        }
        const newDate = new Date(departureDate.getTime());
        setReturnDate(newDate.setDate(departureDate.getDate()));
    }
    const [departureResults, setDepartureResults] = useState([]);
    const [returnResults, setReturnResults] = useState([]);
    const inputDRef = useRef(null);
    const inputRRef = useRef(null);
    const handleDepartureAirportSearch = (e) => {
        const value = e.target.value;
        setDisplayFrom(value);
        if (value.length > 0) {
            const filteredAirports = airports
                .filter(airport =>
                    airport.airportCode.toLowerCase().includes(value.toLowerCase())
                )
                .slice(0, 10);
            setDepartureResults(filteredAirports);
            if (filteredAirports.length === 0) {
                const filteredAirports1 = airports
                    .filter(airport =>
                        airport.cityName.toLowerCase().includes(value.toLowerCase())
                    )
                    .slice(0, 10);
                setDepartureResults(filteredAirports1);
            }
        } else {
            setDepartureResults([]);
        }
    };
    const handleDepartureSelect = (data) => {
        if (data.countryCode !== "IN") {
            setIsDomestic("INTERNATIONAL")
        }
        var DF = data.airportName + ", " + data.cityName + "(" + data.airportCode + ")";
        setDisplayFrom(DF);
        setLocationFrom(data.airportCode)
        setCityFrom(data.cityName);
        setFrom(data.cityName);
        setDepartureResults([]);
    }
    const handleDepartureAirportClick = (e) => {
        const filteredAirports = airports.slice(0, 10);
        setDepartureResults(filteredAirports);
        inputDRef.current.select();
    };
    const handleReturnAirportSearch = (e) => {
        const value = e.target.value;
        setDisplayTo(value);
        if (value.length > 0) {
            const filteredAirports = airports
                .filter(airport =>
                    airport.airportCode.toLowerCase().includes(value.toLowerCase())
                )
                .slice(0, 10);
            setReturnResults(filteredAirports);
            if (filteredAirports.length === 0) {
                const filteredAirports1 = airports
                    .filter(airport =>
                        airport.cityName.toLowerCase().includes(value.toLowerCase())
                    )
                    .slice(0, 10);
                setReturnResults(filteredAirports1);
            }
        } else {
            setReturnResults([]);
        }
    };
    const handleReturnSelect = (data) => {
        var DF = data.airportName + ", " + data.cityName + "(" + data.airportCode + ")";
        if (data.countryCode !== "IN") {
            setIsDomestic("INTERNATIONAL")
        }
        setDisplayTo(DF);
        setLocationTo(data.airportCode)
        setCityTo(data.cityName);
        setTo(data.cityName);
        setReturnResults([]);
    }
    const handleReturnAirportClick = (e) => {
        const filteredAirports = airports.slice(0, 10);
        setReturnResults(filteredAirports);
        inputRRef.current.select();
    };
    /* End Search Form */
    const BindPageData = (searchQuery) => {
        setLoading(true);
        GetFlights(searchQuery);
        setSearchData(searchQuery)
        setJourneyType(searchQuery.journeyType)
        setCabinClass(searchQuery.cabinClass)
        setAdults(searchQuery.adults);
        setKids(searchQuery.kids);
        setInfants(searchQuery.infants);
        setLocationFrom(searchQuery.locationFrom)
        setLocationTo(searchQuery.locationTo)
        setDisplayFrom(searchQuery.displayFrom)
        setDisplayTo(searchQuery.displayTo)
        setCityFrom(searchQuery.cityFrom)
        setCityTo(searchQuery.cityTo)
        setDepartureDate(searchQuery.departureDate)
        setReturnDate(searchQuery.returnDate)
        setIsOneWay(searchQuery.isOneWay)
        setIsDomestic(params.isDomestic);
        setFrom(searchQuery.from);
        setTo(searchQuery.to);
    }
    useEffect(() => {
        if (state === null) {
            navigate('/searchflights')
        }
        else if (params === null) {
            navigate('/searchflights')
        }
        else if (state.searchQuery === null) {
            navigate('/searchflights')
        }
        else {
            if (localStorage.getItem("userId") !== null) {
                setUserId(localStorage.getItem("userId"))
                setRequestedBy("CUSTOMER")
            }
            BindPageData(state.searchQuery)
            /*setLoading(false)
            var ddata = returndata.data;
            setOnwardFlights(ddata.filter(flight => flight.journeyType === 'ONWARD'));
            setReturnFlights(ddata.filter(flight => flight.journeyType === 'RETURN'));
            setTempPackages(returndata.data)
            setStops([...new Set(ddata.map(flight => flight.stops))].sort((a, b) => a - b));
            setAirlines([...new Set(ddata.flatMap(flight => flight.tFSegments.map(segment => segment.airline)))]);*/
        }
    }, []);
    return (
        <div id="main-wrapper">
            {loading === true ? <PageLoader></PageLoader> :
                <div>
                    <Header></Header>
                    <div className="clearfix"></div>
                    <div className="py-3 position-relative" style={{ backgroundColor: 'rgb(58 77 120)' }}>
                        <div className="container">
                           {/* Search Form */}
                           <Collapse in={open}>
                                <div className="row justify-content-center align-items-center">
                                    <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                                        <div className="search-wrap position-relative">
                                            <div className="row align-items-end gy-3 gx-md-3 gx-sm-2">
                                                <div className="col-xl-12 col-lg-12 col-md-12">
                                                    <div className="row gy-3 gx-lg-2 gx-3">
                                                        <div className="col-xl-3 col-lg-6 col-md-6 col-sm-6 position-relative">
                                                            <div className="flx-start mb-sm-0 mb-2">
                                                                <div className="form-check form-check-inline">
                                                                    <input style={{ border: "1px solid" }} className="form-check-input" type="radio" name="trip" id="roundtrip" onChange={e => handleJourneyType(e)} value="ROUNDTRIP" checked={journeyType === "ROUNDTRIP" ? true : false} />
                                                                    <label className="form-check-label text-white" htmlFor="return">Return</label>
                                                                </div>
                                                                <div className="form-check form-check-inline">
                                                                    <input style={{ border: "1px solid" }} className="form-check-input" type="radio" name="trip" id="oneway" onChange={e => handleJourneyType(e)} value="ONEWAY" checked={journeyType === "ONEWAY" ? true : false} />
                                                                    <label className="form-check-label text-white" htmlFor="oneway">One Way</label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 position-relative">
                                                            <div className="flx-start mb-sm-0 mb-2">
                                                                <div className="form-check form-check-inline">
                                                                    <input style={{ border: "1px solid" }} className="form-check-input" type="radio" name="journeytype" id="ECONOMY" onChange={e => handleClassType(e)} value="ECONOMY" checked={cabinClass === "ECONOMY" ? true : false} />
                                                                    <label className="form-check-label text-white" htmlFor="return">ECONOMY</label>
                                                                </div>
                                                                <div className="form-check form-check-inline">
                                                                    <input style={{ border: "1px solid" }} className="form-check-input" type="radio" name="journeytype" id="BUSINESS" onChange={e => handleClassType(e)} value="BUSINESS" checked={cabinClass === "BUSINESS" ? true : false} />
                                                                    <label className="form-check-label text-white" htmlFor="oneway">BUSINESS</label>
                                                                </div>
                                                                <div className="form-check form-check-inline">
                                                                    <input style={{ border: "1px solid" }} className="form-check-input" type="radio" name="journeytype" id="FIRST" onChange={e => handleClassType(e)} value="FIRST" checked={cabinClass === "FIRST" ? true : false} />
                                                                    <label className="form-check-label text-white" htmlFor="oneway">FIRST</label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-xl-12 col-lg-12 col-md-12">
                                                    <div className="row gy-3 gx-lg-2 gx-3">
                                                        <div className="col-xl-2 col-lg-6 col-md-6 col-sm-6 position-relative">
                                                            <div className="form-group hdd-arrow mb-0">
                                                                <h6 className="text-white">FROM</h6>
                                                                <input ref={inputDRef} className="form-control searchTextbox" type="text" placeholder="Search for an airport" value={displayFrom} onChange={handleDepartureAirportSearch} onClick={handleDepartureAirportClick} onFocus={handleDepartureAirportClick} />
                                                                <span className="icon-inside">
                                                                    <svg xmlns="http://www.w3.org/2000/svg" width={20} height={17} fill="#808080" viewBox="0 0 20 17"><path d="M1.376 16.498v-1h17v1h-17zm1.575-5.075L.676 7.498l.85-.2 1.8 1.55 5.4-1.45-4.05-6.85L5.75.223l6.85 6.125 5.225-1.4c.383-.1.73-.03 1.038.212.308.242.462.554.462.938 0 .266-.075.5-.225.7-.15.2-.35.333-.6.4L2.95 11.423z" /></svg>
                                                                </span>
                                                                {errors.displayFrom === false ? (<div className="validationerror">Please select departure </div>) : ('')}
                                                                {departureResults.length > 0 && (
                                                                    <ul className="locationListVideo scrollable-list" style={{ minWidth: "350px" }}>
                                                                        {departureResults.map((airport) => (
                                                                            <li className="locationListVideoItem" key={airport.airportCode} onClick={() => handleDepartureSelect(airport)}>
                                                                                <div className="item-name">{airport.cityName} ({airport.airportCode})</div>
                                                                                <div className="item-details">
                                                                                    <span className="item-code-date">{airport.airportName}</span>
                                                                                    <span className="item-designation">{airport.countryName}</span>
                                                                                </div>
                                                                            </li>
                                                                        ))}
                                                                    </ul>
                                                                )}
                                                            </div>
                                                        </div>
                                                        <div className="col-auto px-1 d-flex align-items-center justify-content-center">
                                                            {/* SVG Icon Between the Textboxes */}
                                                            <svg xmlns="http://www.w3.org/2000/svg" width={32} height={32} fill="none" viewBox="0 0 32 32" className="c-pointer">
                                                                <rect width={32} height={32} fill="#fff" rx={16} />
                                                                <g stroke="#3366CC" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.25" clipPath="url(#clip0_160_1650)">
                                                                    <path d="M24.167 14.833H7.833M7.833 14.833L13.667 9M7.833 18.334h16.334M24.167 18.333l-5.834 5.834" />
                                                                    <circle cx={16} cy={16} r="13.375" />
                                                                </g>
                                                                <defs>
                                                                    <clipPath id="clip0_160_1650">
                                                                        <path fill="#fff" d="M0 0H28V28H0z" transform="translate(2 2)" />
                                                                    </clipPath>
                                                                </defs>
                                                            </svg>
                                                        </div>
                                                        <div className="col-xl-2 col-lg-6 col-md-6 col-sm-6">
                                                            <div className="form-groupp hdd-arrow mb-0">
                                                                <h6 className="text-white">TO</h6>
                                                                <div className="position-relative">
                                                                    <input ref={inputRRef} className="form-control searchTextbox" type="text" placeholder="Search for an airport" value={displayTo} onChange={handleReturnAirportSearch} onClick={handleReturnAirportClick} onFocus={handleReturnAirportClick} />
                                                                    <span className="icon-inside">
                                                                        <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} fill="#808080" viewBox="0 0 24 24"><mask id="mask0_160_1644" style={{ maskType: 'alpha' }} width={24} height={24} x={0} y={0} maskUnits="userSpaceOnUse"><path fill="#808080" d="M0 0H24V24H0z" /></mask><g mask="url(#mask0_160_1644)"><path d="M3.5 20.5v-1h17v1h-17zm15.475-5.1L3.5 11.125V6.7l.8.225L5 9.05l5.5 1.55V2.65l1.125.275 2.75 8.75 5.25 1.475c.267.067.48.209.638.425.158.217.237.459.237.725 0 .384-.162.692-.487.925-.325.234-.671.292-1.038.175z" /></g></svg>
                                                                    </span>
                                                                    {returnResults.length > 0 && (
                                                                        <ul className="locationListVideo scrollable-list" style={{ minWidth: "350px" }}>
                                                                            {returnResults.map((airport) => (
                                                                                <li className="locationListVideoItem" key={airport.airportCode} onClick={() => handleReturnSelect(airport)}>
                                                                                    <div className="item-name">{airport.cityName} ({airport.airportCode})</div>
                                                                                    <div className="item-details">
                                                                                        <span className="item-code-date">{airport.airportName}</span>
                                                                                        <span className="item-designation">{airport.countryName}</span>
                                                                                    </div>
                                                                                </li>
                                                                            ))}
                                                                        </ul>
                                                                    )}
                                                                </div>
                                                                {errors.displayTo === false ? (<div className="validationerror">Please select arrival </div>) : ('')}
                                                            </div>
                                                        </div>
                                                        <div className="col-xl-2 col-lg-6 col-md-6 col-sm-6 position-relative">
                                                            <h6 className="text-white">DEPATURE DATE</h6>
                                                            <div className="position-relative">
                                                                <ReactDatePicker autoComplete="false" className="form-control searchTextbox" wrapperClassName="datePicker" name="departureDate" selected={departureDate} onChange={(date) => handleDepartureDate(date)} dateFormat="EEEE, dd MMM yyyy" minDate={new Date()} />
                                                                <span className="icon-inside"><i className="far fa-calendar-alt" /></span>
                                                                {errors.departureDate === false ? (<div className="validationerror">Please select departureDate </div>) : ('')}
                                                            </div>
                                                        </div>
                                                        <div className="col-xl-2 col-lg-6 col-md-6 col-sm-6 position-relative">
                                                            <h6 className="text-white">RETURN DATE</h6>
                                                            <div className="position-relative">
                                                                {isOneWay === true ?
                                                                    <ReactDatePicker disabled autoComplete="false" className="form-control searchTextbox" wrapperClassName="datePicker" name="returnDate" placeholderText="Return Date" />
                                                                    :
                                                                    <ReactDatePicker autoComplete="false" className="form-control searchTextbox" wrapperClassName="datePicker" name="returnDate" selected={returnDate} onChange={(date) => { handleReturnDate(date) }} dateFormat="EEEE, dd MMM yyyy" minDate={departureDate} />
                                                                }
                                                                <span className="icon-inside"><i className="far fa-calendar-alt" /></span>
                                                                {errors.returnDate === false ? (<div className="validationerror">Please select returnDate </div>) : ('')}
                                                            </div>
                                                        </div>
                                                        <div className="col-xl-2 col-lg-6 col-md-6 col-sm-6 position-relative">
                                                            <h6 className="text-white">TRAVELLERS</h6>
                                                            <div className="form-group mb-0">
                                                                <div className="booking-form__input guests-input mixer-auto" style={{ top: "60px !important" }}>
                                                                    <button name="guests-btn" id="guests-input-btn" className={travelerDisplay === true ? "open" : ""} onClick={handleDisplayClick}><i className="fas fa-users me-2" />{adults + " Adults, " + kids + " Kids, " + infants + " Infants"}</button>
                                                                    <div className={travelerDisplay === true ? "guests-input__options open" : "guests-input__options"} id="guests-input-options">
                                                                        <div>
                                                                            <span className="guests-input__ctrl minus" id="adults-subs-btn" onClick={handleDecreaseAdults}><i className="fa-solid fa-minus" /></span>
                                                                            <span className="guests-input__value"><span defaultValue={1} readOnly value={adults} name="adults">{adults}</span>Adults</span>
                                                                            <span className="guests-input__ctrl plus" id="adults-add-btn" onClick={handleIncreaseAdults}><i className="fa-solid fa-plus" /></span>
                                                                        </div>
                                                                        <div>
                                                                            <span className="guests-input__ctrl minus" id="children-subs-btn" onClick={handleDecreaseKids}><i className="fa-solid fa-minus" /></span>
                                                                            <span className="guests-input__value"><span defaultValue={0} readOnly value={kids} name="kids">{kids}</span>Kids</span>
                                                                            <span className="guests-input__ctrl plus" id="children-add-btn" onClick={handleIncreaseKids}><i className="fa-solid fa-plus" /></span>
                                                                        </div>
                                                                        <div>
                                                                            <span className="guests-input__ctrl minus" id="room-subs-btn" onClick={handleDecreaseInfants}><i className="fa-solid fa-minus" /></span>
                                                                            <span className="guests-input__value"><span defaultValue={0} readOnly value={infants} name="infants">{infants}</span>Infants</span>
                                                                            <span className="guests-input__ctrl plus" id="room-add-btn" onClick={handleIncreaseInfants}><i className="fa-solid fa-plus" /></span>
                                                                        </div>
                                                                        <div className="form-group mb-3">
                                                                            <ul className="flx-start mb-sm-0 mb-2" style={{ textAlign: "left" }}>
                                                                                <li className="mb-2">
                                                                                    <input style={{ border: "1px solid", marginRight: "5px" }} className="form-check-input text-bold" type="radio" name="cabinClass" id="ECONOMY" onChange={e => handleClassType(e)} value="ECONOMY" checked={cabinClass === "ECONOMY" ? true : false} />
                                                                                    <label className="form-check-label" htmlFor="return">ECONOMY</label>
                                                                                </li>
                                                                                <li className="mb-2">
                                                                                    <input style={{ border: "1px solid", marginRight: "5px" }} className="form-check-input text-bold" type="radio" name="cabinClass" id="BUSINESS" onChange={e => handleClassType(e)} value="BUSINESS" checked={cabinClass === "BUSINESS" ? true : false} />
                                                                                    <label className="form-check-label" htmlFor="return">BUSINESS</label>
                                                                                </li>
                                                                                <li>
                                                                                    <input style={{ border: "1px solid", marginRight: "5px" }} className="form-check-input text-bold" type="radio" name="cabinClass" id="FIRST" onChange={e => handleClassType(e)} value="FIRST" checked={cabinClass === "FIRST" ? true : false} />
                                                                                    <label className="form-check-label" htmlFor="return">FIRST</label>
                                                                                </li>
                                                                            </ul>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-auto px-1" style={{ paddingTop: "28px" }}>
                                                            <button type="button" onClick={e => handleSearch(e)} className="btn btn-danger full-width fw-medium"><i className="fa-solid fa-magnifying-glass me-2"></i>SEARCH</button>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Collapse>
                            {/* </row> */}
                        </div>
                    </div>
                    <section className="gray-simple">
                        <div className="container">
                            <div className="row justify-content-between gy-4 gx-xl-4 gx-lg-3 gx-md-3 gx-4">
                                <div className="row">
                                    <div className="col-xl-3 col-lg-4 col-md-12">
                                        <div className="filter-searchBar bg-white rounded-3">
                                            <div className="filter-searchBar-head border-bottom">
                                                <div className="searchBar-headerBody d-flex align-items-start justify-content-between px-3 py-3">
                                                    <div className="searchBar-headerfirst">
                                                        <h6 className="fw-bold fs-5 m-0">Filters</h6>
                                                    </div>
                                                    <div className="searchBar-headerlast text-end">
                                                    <Link onClick={() => setOpen(!open)} className="text-md fw-medium text-primary active">Modify Search</Link>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="filter-searchBar-body">
                                                <div className="searchBar-single px-3 py-3 border-bottom">
                                                    <div className="searchBar-single-title d-flex align-items-center justify-content-between mb-3">
                                                        <h6 className="sidebar-subTitle fs-6 fw-medium m-0">Price Sort</h6>
                                                    </div>
                                                    <div className="searchBar-single-wrap">
                                                        <ul className="row align-items-center justify-content-between p-0 gx-3 gy-2">
                                                            <li className="col-12">
                                                                <div className="form-check">
                                                                    <input style={{ border: "1px solid" }} className="form-check-input" type="radio" name="sort" onClick={e => handleSort(e, 0)} />
                                                                    <label className="form-check-label" htmlFor="direct">Low - High</label>
                                                                </div>
                                                            </li>
                                                            <li className="col-12">
                                                                <div className="form-check">
                                                                    <input style={{ border: "1px solid" }} className="form-check-input" type="radio" name="sort" onClick={e => handleSort(e, 1)} />
                                                                    <label className="form-check-label" htmlFor="1stop">High - Low</label>
                                                                </div>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                                {/* Onward Stops */}
                                                <div className="searchBar-single px-3 py-3 border-bottom">
                                                    <div className="searchBar-single-title d-flex align-items-center justify-content-between mb-3">
                                                        <h6 className="sidebar-subTitle fs-6 fw-medium m-0">Stops</h6>
                                                        <Link onClick={e => handleStops(e, 5)} className="text-md fw-medium text-muted active">Reset</Link>
                                                    </div>
                                                    <div className="searchBar-single-wrap">
                                                        <ul className="row align-items-center justify-content-between p-0 gx-3 gy-2">
                                                            <li className="col-12">
                                                                <div className="form-check">
                                                                    <input style={{ border: "1px solid" }} className="form-check-input" type="radio" name="stop" onClick={e => handleStops(e, 0)} id="direct" />
                                                                    <label className="form-check-label" htmlFor="direct">Non Stop</label>
                                                                </div>
                                                            </li>
                                                            <li className="col-12">
                                                                <div className="form-check">
                                                                    <input style={{ border: "1px solid" }} className="form-check-input" type="radio" name="stop" onClick={e => handleStops(e, 1)} id="1stop" />
                                                                    <label className="form-check-label" htmlFor="direct">1 Stop</label>
                                                                </div>
                                                            </li>
                                                            <li className="col-12">
                                                                <div className="form-check">
                                                                    <input style={{ border: "1px solid" }} className="form-check-input" type="radio" name="stop" onClick={e => handleStops(e, 2)} id="2stop" />
                                                                    <label className="form-check-label" htmlFor="direct">2+ Stop</label>
                                                                </div>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                                {/* Pricing */}
                                                <div className="searchBar-single px-3 py-3 border-bottom">
                                                    <div className="searchBar-single-title d-flex align-items-center justify-content-between mb-3">
                                                        <h6 className="sidebar-subTitle fs-6 fw-medium m-0">Pricing Range</h6>
                                                        <Link onClick={e => handlePriceRange(e, 0, 1000000)} className="text-md fw-medium text-muted active">Reset</Link>
                                                    </div>
                                                    <div className="searchBar-single-wrap">
                                                        <ul className="row align-items-center justify-content-between p-0 gx-3 gy-2">
                                                            <li className="col-12">
                                                                <div className="form-check">
                                                                    <input style={{ border: "1px solid" }} className="form-check-input" type="radio" name="prices" onClick={e => handlePriceRange(e, 1000, 3000)} />
                                                                    <label className="form-check-label" htmlFor="prices">1000 INR to 3000 INR</label>
                                                                </div>
                                                            </li>
                                                            <li className="col-12">
                                                                <div className="form-check">
                                                                    <input style={{ border: "1px solid" }} className="form-check-input" type="radio" name="prices" onClick={e => handlePriceRange(e, 3000, 6000)} />
                                                                    <label className="form-check-label" htmlFor="prices">3000 INR to 6000 INR</label>
                                                                </div>
                                                            </li>
                                                            <li className="col-12">
                                                                <div className="form-check">
                                                                    <input style={{ border: "1px solid" }} className="form-check-input" type="radio" name="prices" onClick={e => handlePriceRange(e, 6000, 10000)} />
                                                                    <label className="form-check-label" htmlFor="prices">6000 INR to 10000 INR</label>
                                                                </div>
                                                            </li>
                                                            <li className="col-12">
                                                                <div className="form-check">
                                                                    <input style={{ border: "1px solid" }} className="form-check-input" type="radio" name="prices" onClick={e => handlePriceRange(e, 10000, 1000000)} />
                                                                    <label className="form-check-label" htmlFor="prices">10000+ INR</label>
                                                                </div>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                                {/* Popular Flights */}
                                                <div className="searchBar-single px-3 py-3 border-bottom">
                                                    <div className="searchBar-single-title d-flex align-items-center justify-content-between mb-3">
                                                        <h6 className="sidebar-subTitle fs-6 fw-medium m-0">Preferred Airlines</h6>
                                                        <Link className="text-md fw-medium text-muted active" onClick={e => handleAirlines(e, "0")}>Reset</Link>
                                                    </div>
                                                    <div className="searchBar-single-wrap">
                                                        <ul className="row align-items-center justify-content-between p-0 gx-3 gy-2">
                                                            {airlines.length > 0 && airlines.map(c =>
                                                                <li className="col-12">
                                                                    <div className="form-check lg">
                                                                        <div className="frm-slicing d-flex align-items-center">
                                                                            <div className="frm-slicing-first">
                                                                                <input style={{ border: "1px solid" }} className="form-check-input" type="radio" id="airlines" name="airlines" onClick={e => handleAirlines(e, c)} />
                                                                                <label className="form-check-label" htmlFor="airlines" />
                                                                            </div>
                                                                            <div className="frm-slicing-end d-flex align-items-center justify-content-between full-width ps-1">
                                                                                <div className="frms-flex d-flex align-items-center">
                                                                                    <div className="frm-slicing-title ps-2"><span className="text-muted-2">{c}</span></div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </li>
                                                            )}
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {/* Side Panel end */}
                                    <div className="col-xl-9 col-lg-4 col-md-12">
                                        <div className="bg-white shadow-md rounded py-4">
                                            <div className="row g-0">
                                                {/* Departure Flights
        ============================================= */}
                                                <div className="col-6">
                                                    <div className="px-3">
                                                        <h4 className="text-5 mb-4">{searchData.cityFrom} <small>to</small> {searchData.cityTo}</h4>
                                                    </div>
                                                    <div className="text-1 bg-light-3 border border-start-0 py-2 px-3">
                                                        <div className="row g-2">
                                                            <div className="col col-sm col-lg-3 text-center d-none d-sm-block">Airline</div>
                                                            <div className="col-6 col-sm col-lg-3 text-center">Departure</div>
                                                            <div className="col-6 col-sm col-lg-3 text-center">Arrival</div>
                                                            <div className="col col-sm col-lg-3 text-end d-none d-sm-block">Price</div>
                                                        </div>
                                                    </div>
                                                    <div className="flight-list round-trip border-end">
                                                        {onwardFlights.length > 0 && onwardFlights.map((flight, index) =>
                                                            <div className="flight-item">
                                                                <div className="row g-2 align-items-center flex-row pt-4 pb-2 px-3">
                                                                    <div className="col-12 col-sm col-lg-3 d-flex align-items-center text-center mb-2 mb-sm-0 company-info">
                                                                        <div className="form-check form-check-inline me-1">
                                                                            <input style={{ border: "1px solid" }} id="departure-flight" name="departure-flight" className="form-check-input" type="radio" onClick={e => handleDepartureFlight(e, flight)} />
                                                                            <label className="form-check-label" htmlFor="departure-flight" />
                                                                        </div>
                                                                        <div className="d-block">
                                                                            <img className="img-fluid" alt src={"/assets/images/airlinelogos/" + flight.tFSegments[0].airlineCode + ".png"} />
                                                                            <span className="d-block text-1 text-dark">{flight.tFSegments[0].airline}</span>
                                                                            <span className="d-block text-1 text-dark">{flight.tFSegments[0].equimentType + "-" + flight.tFSegments[0].flightNumber}</span>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-6 col-sm col-lg-3 text-center time-info"> <span>{moment(flight.tFSegments[0].tFDepartureData.departureDateTime).format('hh:mm A')}</span> <small className="text-muted d-none d-sm-block">{flight.stops === 0 ? "Non Stop" : flight.stops == 1 ? "1 Stop" : flight.stops + "Stops"}</small> </div>
                                                                    <div className="col-6 col-sm col-lg-3 text-center time-info"> <span>{moment(flight.tFSegments.slice(-1)[0].tFArrivalData.arrivalDateTime).format('hh:mm A')}</span> <small className="text-muted d-none d-sm-block">{getTimeFromMins(flight.tFSegments[0].duration)}</small> </div>
                                                                    <div className="col-12 col-sm col-lg-3 text-center text-sm-end text-dark text-4 fw-bold">INR {Math.round(flight.tFPriceDetails.totalFare)}</div>
                                                                    <div className="col-12 col-sm col-lg-3 text-center text-sm-end text-dark text-4 fw-bold">{flight.tFPriceDetails.fareIdentifier === "SPECIAL_RETURN" ? <span className="badge bg-success py-1 px-2 fw-normal text-1">SPECIAL PRICE</span> : <span className="badge bg-success py-1 px-2 fw-normal text-1">{flight.tFPriceDetails.fareIdentifier}</span>}</div>
                                                                    <div className="col col-sm-auto col-lg-3 ms-auto text-0 text-center text-sm-end"><a data-bs-toggle="modal" data-bs-target={"#flight" + index} href>Flight Details</a></div>
                                                                </div>
                                                                <div id={"flight" + index} className="modal fade" role="dialog" aria-hidden="true">
                                                                    <div className="modal-dialog modal-lg modal-dialog-centered" role="document">
                                                                        <div className="modal-content">
                                                                            <div className="modal-header">
                                                                                <h5 className="modal-title">Flight Details</h5>
                                                                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
                                                                            </div>
                                                                            <div className="modal-body">
                                                                                <div className="flight-details">
                                                                                    <div className="row mb-4">
                                                                                        <div className="col-12 col-sm-9 col-lg-8">
                                                                                            <div className="row align-items-center trip-title mb-3">
                                                                                                <div className="col col-sm-auto text-center text-sm-start">
                                                                                                    <h5 className="m-0 trip-place">{flight.tFSegments[0].tFDepartureData.city}</h5>
                                                                                                </div>
                                                                                                <div className="col-auto text-10 text-black-50 text-center trip-arrow">➝</div>
                                                                                                <div className="col col-sm-auto text-center text-sm-start">
                                                                                                    <h5 className="m-0 trip-place">{flight.tFSegments.slice(-1)[0].tFArrivalData.city}</h5>
                                                                                                </div>
                                                                                            </div>
                                                                                            <div className="row align-items-center">
                                                                                                <div className="col col-sm-auto"><span className="text-4">{moment(flight.tFSegments[0].tFDepartureData.departureDateTime).format('Do MMM YYYY ddd')}</span></div>
                                                                                                <div className="col-auto">{flight.tFPriceDetails.tFAdults.refundable === "NonRefundable" ? <span className="badge bg-danger py-1 px-2 fw-normal text-1">NonRefundable</span> : <span className="badge bg-success py-1 px-2 fw-normal text-1">Refundable</span>}</div>
                                                                                                <div className="col-auto"><span className="badge bg-success py-1 px-2 fw-normal text-1">{flight.tFPriceDetails.fareIdentifier}</span></div>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="col-12 col-sm-3 col-lg-2 text-end ms-auto"><h5 className="trip-place">INR {Math.round(flight.tFPriceDetails.totalFare)}</h5></div>
                                                                                    </div>
                                                                                    <ul className="nav nav-tabs" id={"myTab" + index} role="tablist">
                                                                                        <li className="nav-item"> <a className="nav-link active" id={"first-tab" + index} data-bs-toggle="tab" href={"#first" + index} role="tab" aria-controls={"first" + index} aria-selected="true">Itinerary</a> </li>
                                                                                        <li className="nav-item"> <a className="nav-link" id={"second-tab" + index} data-bs-toggle="tab" href={"#second" + index} role="tab" aria-controls={"second" + index} aria-selected="false">Fare Details</a> </li>
                                                                                        <li className="nav-item"> <a className="nav-link" id={"third-tab" + index} data-bs-toggle="tab" href={"#third" + index} role="tab" aria-controls={"third" + index} aria-selected="false">Baggage Details</a> </li>
                                                                                        <li className="nav-item"> <a className="nav-link" id={"fourth-tab" + index} data-bs-toggle="tab" href={"#fourth" + index} role="tab" aria-controls={"fourth" + index} aria-selected="false">Cancellation Fee</a> </li>
                                                                                    </ul>
                                                                                    <div className="tab-content my-3" id="myTabContent">
                                                                                        <div className="tab-pane fade show active" id={"first" + index} role="tabpanel" aria-labelledby="first-tab">
                                                                                            {flight.tFSegments.length > 0 && flight.tFSegments.map(seg =>
                                                                                                <div className="row flex-row pt-4 px-md-4">
                                                                                                    <div className="col-12 col-sm-3 text-center d-lg-flex company-info"> <span className="align-middle"><img className="img-fluid" alt src={"/assets/images/airlinelogos/" + seg.airlineCode + ".png"} /> </span> <span className="align-middle ms-lg-2"> <span className="d-block text-1 text-dark">{seg.airline}</span> <small className="text-muted d-block">{seg.equimentType + "-" + seg.flightNumber}</small> </span> </div>
                                                                                                    <div className="col-12 col-sm-3 text-center time-info mt-3 mt-sm-0"> <span className="text-5 text-dark">{moment(seg.tFDepartureData.departureDateTime).format('hh:mm A')}</span> <small className="text-muted d-block">{seg.tFDepartureData.airportName}, {seg.tFDepartureData.city}</small> </div>
                                                                                                    <div className="col-12 col-sm-3 text-center time-info mt-3 mt-sm-0"> <span className="text-3 text-dark">{getTimeFromMins(seg.duration)}</span> <small className="text-muted d-block">Duration</small> </div>
                                                                                                    <div className="col-12 col-sm-3 text-center time-info mt-3 mt-sm-0"> <span className="text-5 text-dark">{moment(seg.tFArrivalData.arrivalDateTime).format('hh:mm A')}</span> <small className="text-muted d-block">{seg.tFArrivalData.airportName}, {seg.tFArrivalData.city}</small> </div>
                                                                                                </div>
                                                                                            )}
                                                                                        </div>
                                                                                        <div className="tab-pane fade" id={"second" + index} role="tabpanel" aria-labelledby="second-tab">
                                                                                            <div className="table-responsive-md">
                                                                                                <table className="table table-hover table-bordered">
                                                                                                    <thead>
                                                                                                        <tr>
                                                                                                            <th></th>
                                                                                                            <th className="text-center">Base Fare</th>
                                                                                                            <td className="text-center">Airline Taxes</td>
                                                                                                            <td className="text-center">Total</td>
                                                                                                        </tr>
                                                                                                    </thead>
                                                                                                    <tbody>
                                                                                                        <tr>
                                                                                                            <td>Adult</td>
                                                                                                            <td className="text-center">{Math.round(flight.tFPriceDetails.tFAdults.baseFare)}</td>
                                                                                                            <td className="text-center">{Math.round(flight.tFPriceDetails.tFAdults.tax)}</td>
                                                                                                            <td className="text-center">{Math.round(flight.tFPriceDetails.tFAdults.totalFare)}</td>
                                                                                                        </tr>
                                                                                                        {flight.tFPriceDetails.tFKids !== null ?
                                                                                                            <tr>
                                                                                                                <td>Kid</td>
                                                                                                                <td className="text-center">{Math.round(flight.tFPriceDetails.tFKids.baseFare)}</td>
                                                                                                                <td className="text-center">{Math.round(flight.tFPriceDetails.tFKids.tax)}</td>
                                                                                                                <td className="text-center">{Math.round(flight.tFPriceDetails.tFKids.totalFare)}</td>
                                                                                                            </tr>
                                                                                                            : ""
                                                                                                        }
                                                                                                        {flight.tFPriceDetails.tFInfants !== null ?
                                                                                                            <tr>
                                                                                                                <td>Infant</td>
                                                                                                                <td className="text-center">{Math.round(flight.tFPriceDetails.tFInfants.baseFare)}</td>
                                                                                                                <td className="text-center">{Math.round(flight.tFPriceDetails.tFInfants.tax)}</td>
                                                                                                                <td className="text-center">{Math.round(flight.tFPriceDetails.tFInfants.totalFare)}</td>
                                                                                                            </tr> : ""
                                                                                                        }
                                                                                                    </tbody>
                                                                                                </table>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="tab-pane fade" id={"third" + index} role="tabpanel" aria-labelledby="third-tab">
                                                                                            <div className="table-responsive-md">
                                                                                                <table className="table table-hover table-bordered">
                                                                                                    <thead>
                                                                                                        <tr>
                                                                                                            <th>&nbsp;</th>
                                                                                                            <td className="text-center">Cabin</td>
                                                                                                            <td className="text-center">Check-In</td>
                                                                                                        </tr>
                                                                                                    </thead>
                                                                                                    <tbody>
                                                                                                        <tr>
                                                                                                            <td>Adult</td>
                                                                                                            <td className="text-center">{flight.tFPriceDetails.tFAdults.cabinBag}</td>
                                                                                                            <td className="text-center">{flight.tFPriceDetails.tFAdults.checkinBag}</td>
                                                                                                        </tr>
                                                                                                        {flight.tFPriceDetails.tFKids !== null ?
                                                                                                            <tr>
                                                                                                                <td>Child</td>
                                                                                                                <td className="text-center">{flight.tFPriceDetails.tFKids.cabinBag}</td>
                                                                                                                <td className="text-center">{flight.tFPriceDetails.tFKids.checkinBag}</td>
                                                                                                            </tr>
                                                                                                            :
                                                                                                            <tr>
                                                                                                                <td>Child</td>
                                                                                                                <td className="text-center">7 Kg</td>
                                                                                                                <td className="text-center">15 Kg</td>
                                                                                                            </tr>
                                                                                                        }
                                                                                                        {flight.tFPriceDetails.tFInfants !== null ?
                                                                                                            <tr>
                                                                                                                <td>Infant</td>
                                                                                                                <td className="text-center">{flight.tFPriceDetails.tFInfants.cabinBag}</td>
                                                                                                                <td className="text-center">{flight.tFPriceDetails.tFInfants.checkinBag}</td>
                                                                                                            </tr>
                                                                                                            :
                                                                                                            <tr>
                                                                                                                <td>Infant</td>
                                                                                                                <td className="text-center">0 Kg</td>
                                                                                                                <td className="text-center">0 Kg</td>
                                                                                                            </tr>
                                                                                                        }
                                                                                                    </tbody>
                                                                                                </table>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="tab-pane fade" id={"fourth" + index} role="tabpanel" aria-labelledby="fourth-tab">
                                                                                            <p className="fw-bold">Terms &amp; Conditions</p>
                                                                                            <ul>
                                                                                                <li>The penalty is subject to 4 hrs before departure. No Changes are allowed after that.</li>
                                                                                                <li>The charges are per passenger per sector.</li>
                                                                                                <li>Partial cancellation is not allowed on tickets booked under special discounted fares.</li>
                                                                                                <li>In case of no-show or ticket not cancelled within the stipulated time, only statutory taxes are refundable subject to Service Fee.</li>
                                                                                                <li>No Baggage Allowance for Infants</li>
                                                                                                <li>Airline penalty needs to be reconfirmed prior to any amendments or cancellation.</li>
                                                                                            </ul>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>
                                                {/* Departure Flights end */}
                                                {/* Returns Flights
        ============================================= */}
                                                <div className="col-6">
                                                    <div className="px-3">
                                                        <h4 className="text-5 mb-4">{searchData.cityTo} <small>to</small> {searchData.cityFrom}</h4>
                                                    </div>
                                                    <div className="text-1 bg-light-3 border border-end-0 border-start-0 py-2 px-3">
                                                        <div className="row g-2">
                                                            <div className="col col-sm col-lg-3 text-center d-none d-sm-block">Airline</div>
                                                            <div className="col-6 col-sm col-lg-3 text-center">Departure</div>
                                                            <div className="col-6 col-sm col-lg-3 text-center">Arrival</div>
                                                            <div className="col col-sm col-lg-3 text-end d-none d-sm-block">Price</div>
                                                        </div>
                                                    </div>
                                                    <div className="flight-list round-trip">
                                                        {returnFlights.length > 0 && returnFlights.map((flight, index) =>
                                                            <div className="flight-item">
                                                                <div className="row g-2 align-items-center flex-row pt-4 pb-2 px-3">
                                                                    <div className="col-12 col-sm col-lg-3 d-flex align-items-center text-center mb-2 mb-sm-0 company-info">
                                                                        <div className="form-check form-check-inline me-1">
                                                                            <input style={{ border: "1px solid" }} id={"return-flight" + index} name="return-flight" className="form-check-input" type="radio" onClick={e => handleReturnFlight(e, flight)} />
                                                                            <label className="form-check-label" htmlFor={"return-flight" + index} />
                                                                        </div>
                                                                        <div className="d-block"> <img className="img-fluid" alt src={"/assets/images/airlinelogos/" + flight.tFSegments[0].airlineCode + ".png"} />
                                                                        <span className="d-block text-1 text-dark">{flight.tFSegments[0].airline}</span>
                                                                        <span className="d-block text-1 text-dark">{flight.tFSegments[0].equimentType + "-" + flight.tFSegments[0].flightNumber}</span>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-6 col-sm col-lg-3 text-center time-info"> <span>{moment(flight.tFSegments[0].tFDepartureData.departureDateTime).format('DD MMM YY hh:mm A')}</span> <small className="text-muted d-none d-sm-block">{flight.stops === 0 ? "Non Stop" : flight.stops == 1 ? "1 Stop" : flight.stops + "Stops"}</small> </div>
                                                                    <div className="col-6 col-sm col-lg-3 text-center time-info"> <span>{moment(flight.tFSegments.slice(-1)[0].tFArrivalData.arrivalDateTime).format('DD MMM YY hh:mm A')}</span> <small className="text-muted d-none d-sm-block">{getTimeFromMins(flight.tFSegments[0].duration)}</small> </div>
                                                                    <div className="col-12 col-sm col-lg-3 text-center text-sm-end text-dark text-4 fw-bold">INR {Math.round(flight.tFPriceDetails.totalFare)}</div>
                                                                    <div className="col-12 col-sm col-lg-3 text-center text-sm-end text-dark text-4 fw-bold">{flight.tFPriceDetails.fareIdentifier === "SPECIAL_RETURN" ? <span className="badge bg-success py-1 px-2 fw-normal text-1">SPECIAL PRICE</span> : <span className="badge bg-success py-1 px-2 fw-normal text-1">{flight.tFPriceDetails.fareIdentifier}</span>}</div>
                                                                    <div className="col col-sm-auto col-lg-3 ms-auto text-0 text-center text-sm-end"><a data-bs-toggle="modal" data-bs-target={"#rflight" + index} href>Flight Details</a></div>
                                                                </div>
                                                                <div id={"rflight" + index} className="modal fade" role="dialog" aria-hidden="true">
                                                                    <div className="modal-dialog modal-lg modal-dialog-centered" role="document">
                                                                        <div className="modal-content">
                                                                            <div className="modal-header">
                                                                                <h5 className="modal-title">Flight Details</h5>
                                                                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
                                                                            </div>
                                                                            <div className="modal-body">
                                                                                <div className="flight-details">
                                                                                    <div className="row mb-4">
                                                                                        <div className="col-12 col-sm-9 col-lg-8">
                                                                                            <div className="row align-items-center trip-title mb-3">
                                                                                                <div className="col col-sm-auto text-center text-sm-start">
                                                                                                    <h5 className="m-0 trip-place">{flight.tFSegments[0].tFDepartureData.city}</h5>
                                                                                                </div>
                                                                                                <div className="col-auto text-10 text-black-50 text-center trip-arrow">➝</div>
                                                                                                <div className="col col-sm-auto text-center text-sm-start">
                                                                                                    <h5 className="m-0 trip-place">{flight.tFSegments.slice(-1)[0].tFArrivalData.city}</h5>
                                                                                                </div>
                                                                                            </div>
                                                                                            <div className="row align-items-center">
                                                                                                <div className="col col-sm-auto"><span className="text-4">{moment(flight.tFSegments[0].tFDepartureData.departureDateTime).format('Do MMM YYYY ddd')}</span></div>
                                                                                                <div className="col-auto">{flight.tFPriceDetails.tFAdults.refundable === "NonRefundable" ? <span className="badge bg-danger py-1 px-2 fw-normal text-1">NonRefundable</span> : <span className="badge bg-success py-1 px-2 fw-normal text-1">Refundable</span>}</div>
                                                                                                <div className="col-auto">{flight.tFPriceDetails.fareIdentifier === "SPECIAL_RETURN" ? <span className="badge bg-success py-1 px-2 fw-normal text-1">SPECIAL PRICE</span> : ""}</div>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="col-12 col-sm-3 col-lg-2 text-end ms-auto"><h5 className="trip-place">INR {Math.round(flight.tFPriceDetails.totalFare)}</h5></div>
                                                                                    </div>
                                                                                    <ul className="nav nav-tabs" id={"myrTab" + index} role="tablist">
                                                                                        <li className="nav-item"> <a className="nav-link active" id={"rfirst-tab" + index} data-bs-toggle="tab" href={"#rfirst" + index} role="tab" aria-controls={"rfirst" + index} aria-selected="true">Itinerary</a> </li>
                                                                                        <li className="nav-item"> <a className="nav-link" id={"rsecond-tab" + index} data-bs-toggle="tab" href={"#rsecond" + index} role="tab" aria-controls={"rsecond" + index} aria-selected="false">Fare Details</a> </li>
                                                                                        <li className="nav-item"> <a className="nav-link" id={"rthird-tab" + index} data-bs-toggle="tab" href={"#rthird" + index} role="tab" aria-controls={"rthird" + index} aria-selected="false">Baggage Details</a> </li>
                                                                                        <li className="nav-item"> <a className="nav-link" id={"rfourth-tab" + index} data-bs-toggle="tab" href={"#rfourth" + index} role="tab" aria-controls={"rfourth" + index} aria-selected="false">Cancellation Fee</a> </li>
                                                                                    </ul>
                                                                                    <div className="tab-content my-3" id="myrTabContent">
                                                                                        <div className="tab-pane fade show active" id={"rfirst" + index} role="tabpanel" aria-labelledby="rfirst-tab">
                                                                                            {flight.tFSegments.length > 0 && flight.tFSegments.map(seg =>
                                                                                                <div className="row flex-row pt-4 px-md-4">
                                                                                                    <div className="col-12 col-sm-3 text-center d-lg-flex company-info"> <span className="align-middle"><img className="img-fluid" alt src={"/assets/images/airlinelogos/" + seg.airlineCode + ".png"} /> </span> <span className="align-middle ms-lg-2"> <span className="d-block text-1 text-dark">{seg.airline}</span> <small className="text-muted d-block">{seg.equimentType + "-" + seg.flightNumber}</small> </span> </div>
                                                                                                    <div className="col-12 col-sm-3 text-center time-info mt-3 mt-sm-0"> <span className="text-5 text-dark">{moment(seg.tFDepartureData.departureDateTime).format('DD MMM YY hh:mm A')}</span> <small className="text-muted d-block">{seg.tFDepartureData.airportName}, {seg.tFDepartureData.city}</small> </div>
                                                                                                    <div className="col-12 col-sm-3 text-center time-info mt-3 mt-sm-0"> <span className="text-3 text-dark">{getTimeFromMins(seg.duration)}</span> <small className="text-muted d-block">Duration</small> </div>
                                                                                                    <div className="col-12 col-sm-3 text-center time-info mt-3 mt-sm-0"> <span className="text-5 text-dark">{moment(seg.tFArrivalData.arrivalDateTime).format('DD MMM YY hh:mm A')}</span> <small className="text-muted d-block">{seg.tFArrivalData.airportName}, {seg.tFArrivalData.city}</small> </div>
                                                                                                </div>
                                                                                            )}
                                                                                        </div>
                                                                                        <div className="tab-pane fade" id={"rsecond" + index} role="tabpanel" aria-labelledby="rsecond-tab">
                                                                                            <div className="table-responsive-md">
                                                                                                <table className="table table-hover table-bordered">
                                                                                                    <thead>
                                                                                                        <tr>
                                                                                                            <th></th>
                                                                                                            <th className="text-center">Base Fare</th>
                                                                                                            <td className="text-center">Airline Taxes</td>
                                                                                                            <td className="text-center">Total</td>
                                                                                                        </tr>
                                                                                                    </thead>
                                                                                                    <tbody>
                                                                                                        <tr>
                                                                                                            <td>Adult</td>
                                                                                                            <td className="text-center">{Math.round(flight.tFPriceDetails.tFAdults.baseFare)}</td>
                                                                                                            <td className="text-center">{Math.round(flight.tFPriceDetails.tFAdults.tax)}</td>
                                                                                                            <td className="text-center">{Math.round(flight.tFPriceDetails.tFAdults.totalFare)}</td>
                                                                                                        </tr>
                                                                                                        {flight.tFPriceDetails.tFKids !== null ?
                                                                                                            <tr>
                                                                                                                <td>Kid</td>
                                                                                                                <td className="text-center">{Math.round(flight.tFPriceDetails.tFKids.baseFare)}</td>
                                                                                                                <td className="text-center">{Math.round(flight.tFPriceDetails.tFKids.tax)}</td>
                                                                                                                <td className="text-center">{Math.round(flight.tFPriceDetails.tFKids.totalFare)}</td>
                                                                                                            </tr>
                                                                                                            : ""
                                                                                                        }
                                                                                                        {flight.tFPriceDetails.tFInfants !== null ?
                                                                                                            <tr>
                                                                                                                <td>Infant</td>
                                                                                                                <td className="text-center">{Math.round(flight.tFPriceDetails.tFInfants.baseFare)}</td>
                                                                                                                <td className="text-center">{Math.round(flight.tFPriceDetails.tFInfants.tax)}</td>
                                                                                                                <td className="text-center">{Math.round(flight.tFPriceDetails.tFInfants.totalFare)}</td>
                                                                                                            </tr> : ""
                                                                                                        }
                                                                                                    </tbody>
                                                                                                </table>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="tab-pane fade" id={"rthird" + index} role="tabpanel" aria-labelledby="rthird-tab">
                                                                                            <div className="table-responsive-md">
                                                                                                <table className="table table-hover table-bordered">
                                                                                                    <thead>
                                                                                                        <tr>
                                                                                                            <th>&nbsp;</th>
                                                                                                            <td className="text-center">Cabin</td>
                                                                                                            <td className="text-center">Check-In</td>
                                                                                                        </tr>
                                                                                                    </thead>
                                                                                                    <tbody>
                                                                                                        <tr>
                                                                                                            <td>Adult</td>
                                                                                                            <td className="text-center">{flight.tFPriceDetails.tFAdults.cabinBag}</td>
                                                                                                            <td className="text-center">{flight.tFPriceDetails.tFAdults.checkinBag}</td>
                                                                                                        </tr>
                                                                                                        {flight.tFPriceDetails.tFKids !== null ?
                                                                                                            <tr>
                                                                                                                <td>Child</td>
                                                                                                                <td className="text-center">{flight.tFPriceDetails.tFKids.cabinBag}</td>
                                                                                                                <td className="text-center">{flight.tFPriceDetails.tFKids.checkinBag}</td>
                                                                                                            </tr>
                                                                                                            :
                                                                                                            <tr>
                                                                                                                <td>Child</td>
                                                                                                                <td className="text-center">7 Kg</td>
                                                                                                                <td className="text-center">15 Kg</td>
                                                                                                            </tr>
                                                                                                        }
                                                                                                        {flight.tFPriceDetails.tFInfants !== null ?
                                                                                                            <tr>
                                                                                                                <td>Infant</td>
                                                                                                                <td className="text-center">{flight.tFPriceDetails.tFInfants.cabinBag}</td>
                                                                                                                <td className="text-center">{flight.tFPriceDetails.tFInfants.checkinBag}</td>
                                                                                                            </tr>
                                                                                                            :
                                                                                                            <tr>
                                                                                                                <td>Infant</td>
                                                                                                                <td className="text-center">0 Kg</td>
                                                                                                                <td className="text-center">0 Kg</td>
                                                                                                            </tr>
                                                                                                        }
                                                                                                    </tbody>
                                                                                                </table>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="tab-pane fade" id={"rfourth" + index} role="tabpanel" aria-labelledby="rfourth-tab">
                                                                                            <p className="fw-bold">Terms &amp; Conditions</p>
                                                                                            <ul>
                                                                                                <li>The penalty is subject to 4 hrs before departure. No Changes are allowed after that.</li>
                                                                                                <li>The charges are per passenger per sector.</li>
                                                                                                <li>Partial cancellation is not allowed on tickets booked under special discounted fares.</li>
                                                                                                <li>In case of no-show or ticket not cancelled within the stipulated time, only statutory taxes are refundable subject to Service Fee.</li>
                                                                                                <li>No Baggage Allowance for Infants</li>
                                                                                                <li>Airline penalty needs to be reconfirmed prior to any amendments or cancellation.</li>
                                                                                            </ul>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>
                                                {/* Returns Flights end */}
                                            </div>
                                        </div>
                                    </div>
                                    {selectOnwardFlight !== null || selectReturnFlight !== null ?
                                        <div className="round-trip-fare fixed-bottom bg-dark shadow-md text-light">
                                            <div className="container p-2 p-sm-4">
                                                <div className="row align-items-center">
                                                    <div className="col-9">
                                                        <div className="row">
                                                            {selectOnwardFlight !== null ?
                                                                <div className="col-12 col-sm-6 border-end border-secondary">
                                                                    <div className="row align-items-center flex-row">
                                                                        <div className="col col-sm-4 col-md-3 text-center d-lg-flex company-info"> <span className="align-middle d-none d-sm-block"><img alt src={"/assets/images/airlinelogos/" + selectOnwardFlight.tFSegments[0].airlineCode + ".png"} /> </span> <span className="align-middle ms-lg-2"> <span className="d-block text-1">{selectOnwardFlight.tFSegments[0].airline}</span> <small className="text-white-50 d-block">{selectOnwardFlight.tFSegments[0].equimentType}-{selectOnwardFlight.tFSegments[0].flightNumber}</small> </span> </div>
                                                                        <div className="col col-sm-4 col-md-3 text-center time-info"> <span className="text-4">{moment(selectOnwardFlight.tFSegments[0].tFDepartureData.departureDateTime).format('hh:mm A')}</span> <small className="text-white-50 d-none d-lg-block">{selectOnwardFlight.tFSegments[0].tFDepartureData.city}</small> </div>
                                                                        <div className="col col-sm-4 col-md-3 text-center time-info"> <span className="text-4">{moment(selectOnwardFlight.tFSegments.slice(-1)[0].tFArrivalData.arrivalDateTime).format('hh:mm A')}</span> <small className="text-white-50 d-none d-lg-block">{selectOnwardFlight.tFSegments.slice(-1)[0].tFArrivalData.city}</small> </div>
                                                                        <div className="col-md-3 text-center d-none d-md-block time-info"> <span className="text-1">{getTimeFromMins(selectOnwardFlight.tFSegments[0].duration)}</span> <small className="text-white-50 d-block">{selectOnwardFlight.stops === 0 ? "Non Stop" : selectOnwardFlight.stops == 1 ? "1 Stop" : selectOnwardFlight.stops + "Stops"}</small> </div>
                                                                    </div>
                                                                </div> : ""}
                                                            <div className="col-12 d-block d-sm-none">
                                                                <hr className="my-1 border-dark" />
                                                            </div>
                                                            {selectReturnFlight !== null ?
                                                                <div className="col-12 col-sm-6 border-end border-secondary">
                                                                    <div className="row align-items-center flex-row">
                                                                        <div className="col col-sm-4 col-md-3 text-center d-lg-flex company-info"> <span className="align-middle d-none d-sm-block"><img alt src={"/assets/images/airlinelogos/" + selectReturnFlight.tFSegments[0].airlineCode + ".png"} /> </span> <span className="align-middle ms-lg-2"> <span className="d-block text-1">{selectReturnFlight.tFSegments[0].airline}</span> <small className="text-white-50 d-block">{selectReturnFlight.tFSegments[0].equimentType}-{selectReturnFlight.tFSegments[0].flightNumber}</small> </span> </div>
                                                                        <div className="col col-sm-4 col-md-3 text-center time-info"> <span className="text-4">{moment(selectReturnFlight.tFSegments[0].tFDepartureData.departureDateTime).format('hh:mm A')}</span> <small className="text-white-50 d-none d-lg-block">{selectReturnFlight.tFSegments[0].tFDepartureData.city}</small> </div>
                                                                        <div className="col col-sm-4 col-md-3 text-center time-info"> <span className="text-4">{moment(selectReturnFlight.tFSegments.slice(-1)[0].tFArrivalData.arrivalDateTime).format('hh:mm A')}</span> <small className="text-white-50 d-none d-lg-block">{selectReturnFlight.tFSegments.slice(-1)[0].tFArrivalData.city}</small> </div>
                                                                        <div className="col-md-3 text-center d-none d-md-block time-info"> <span className="text-1">{getTimeFromMins(selectReturnFlight.tFSegments[0].duration)}</span> <small className="text-white-50 d-block">{selectReturnFlight.stops === 0 ? "Non Stop" : selectReturnFlight.stops == 1 ? "1 Stop" : selectOnwardFlight.stops + "Stops"}</small> </div>
                                                                    </div>
                                                                </div> : ""}
                                                        </div>
                                                    </div>
                                                    <div className="col-3">
                                                        <div className="row align-items-center">
                                                            {selectOnwardFlight !== null || selectReturnFlight !== null ? <div className="col-12 col-md-6 text-light text-center text-6 price"><span className="text-1 text-white-50 d-none d-lg-block">Total Fare: </span>INR {Math.round(totalFare)}</div> : ""}
                                                            {selectOnwardFlight !== null && selectReturnFlight !== null ? <div className="col-12 col-md-6 text-center mt-2 mt-md-0 btn-book"> <button onClick={e => handleSubmit(e)} className="btn btn-sm btn-primary px-sm-2 px-lg-3"><i className="fas fa-shopping-cart d-block d-lg-none" /> <span className="d-none d-lg-block">Book Now</span></button> </div> : ""}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div> : ""}
                                </div>
                            </div>
                        </div>
                    </section>
                    <Footer></Footer>
                </div>}</div>
    )
}