import React from 'react';
import { Link } from 'react-router-dom';

const TopHeader = () => {
    return (
        <div className="small-header">
            <div className="left-content">
                <div className="header-socials">
                    <ul>
                        <li><Link to={"https://facebook.com/landair"} target="_blank" style={{ color: "#fff" }}><i className="fa-brands fa-facebook" /></Link></li>
                        <li><Link to={"https://twitter.com/landair"} target="_blank" style={{ color: "#fff" }}><i className="fa-brands fa-twitter" /></Link></li>
                        <li><Link to={"https://instagram.com/landair"} target="_blank" style={{ color: "#fff" }}><i className="fa-brands fa-instagram" /></Link></li>
                    </ul>
                </div>
            </div>
            <div className="right-content">
            <div className="header-socials">
                <p style={{color:'#fff'}}><i className="fa fa-phone me-1" /> +91 7995331116</p></div>
            </div>
        </div>
    );
};
export default TopHeader;